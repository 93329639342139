<template>
  <div class="container-fluid">
    <!-- Encabezado imagen y boton -->
    <div class="row justify-content-center mt-2 mb-5 ml-md-4">
      <div class="row justify-content-center justify-content-md-start col-6">
        <img src="images/hr/empleados.png"
        class="rounded-circle rounded mx-2"
        width="100" height="100" alt="Empleados">
        <h1 class="row mx-1 mt-md-5">Empleados</h1>
      </div>
      <div class="row justify-content-center justify-content-md-end col-12 col-md-6 col-lg-6 mt-md-5 mr-md-4">
        <a href="#" class="btn btn-info btn-lg font-weight-bold mt-2 col-10 col-lg-8"
        v-on:click.prevent="createEmployee()">Registro</a>
      </div>
    </div>
    <!-- TABLA -->
    <div class="container-fluid justify-content-around">
      <div class="container-fluid justify-content-around">
        <div class="container d-flex justify-content-around col-12">
          <div class="table table-responsive table-sm">
            <table id="table" class="table table-hover table-striped table-bordered">
              <thead class="bg-dark text-white">
                <tr>
                  <th>Empleado</th>
                  <th>Checador</th>
                  <th>Apellido</th>
                  <th>Nombre</th>
                  <th>Compañia</th>
                  <th>Departamento</th>
                  <th>Puesto</th>
                  <th>Contratación</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="employee in employees">
                  <td class="text-right" id="ajua">
                    <a href="#" v-on:click.prevent="showEmployee(employee)">{{ employee.employeenumber }}</a>
                  </td>
                  <td class="text-center">{{ employee.timeclocknumber }}</td>
                  <td class="text-left">&nbsp;{{ employee.lastname }}</td>
                  <td class="text-left">&nbsp;{{ employee.name }}</td>
                  <td class="text-left">&nbsp;{{ employee.company }}</td>
                  <td class="text-left">&nbsp;{{ employee.department }}</td>
                  <td class="text-left">&nbsp;{{ employee.role }}</td>
                  <td class="text-center">&nbsp;{{ employee.hired }}</td>
                  <td class="text-left">&nbsp;{{ employee.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- INICIO DEL MODAL -->
    <div class="col-10 col-md-6 col-lg-4">
      <div class="modal fade text-dark" id="show_employee" role="dialog" aria-labelledby="modal" aria-hidden="true" ref="showemployee_modal">
        <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
          <div class="modal-content modalbg">
            <div class="modal-header" id="titulomodal">
              <img src="images/hr/empleado.png"
              class="mx-3 d-none d-sm-block"
              width="75" height="75" alt="employeeinfo">
              <h3 class="modal-title font-weight-bold mx-auto ml-md-1 mt-auto">Información del empleado</h3>
              <button type="button" class="close mr-1" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="justify-content-center">
                    <div class="tabbable" id="tabs">
                      <ul class="nav nav-tabs" role="tablist">
                        <li><a class="nav-link active" href="#Personal" data-toggle="tab">Personal</a></li>
                        <li><a class="nav-link" href="#Contacto" data-toggle="tab">Contacto</a></li>
                        <li><a class="nav-link" href="#Beneficiario" data-toggle="tab">Beneficiario</a></li>
                        <li><a class="nav-link" href="#Nomina" data-toggle="tab">Nómina</a></li>
                        <li><a class="nav-link" href="#Expediente" data-toggle="tab">Expediente</a></li>
                      </ul>
                      <!-- tabs-panels inside of component-->
                      <div class="tab-content">
                          <!-- Employee info personal -->
                          <div role="tabpanel" class="tab-pane active" id="Personal">
                            <div class="row col-12">
                              <span class="font-italic text-muted text-center" v-if="editMode || createMode">
                                  Los campos no habilitados se actualizan desde su respectivo apartado, y/o se generan de manera automática.
                              </span>
                            </div>
                            <div class="container justify-content-center text-center font-weight-bold">
                              <div class="row justify-content-center">
                                <!-- Employee's photo and company's image -->
                                <div class="container col-12 col-lg-4">
                                  <!-- <div v-if="!editMode && !createMode"> -->
                                  <div v-if="!createMode && !editMode">
                                    <span>Fotografía</span>
                                    <img v-bind:src="'images/hr/'+employee.photo"
                                    class="m-auto p-2 border border-info col-12"
                                    width="85%" alt="employeephoto"/>
                                  </div>
                                  <div v-else id="preview" class="container col-12"></div>
                                  <div v-if="editMode || createMode" class="my-5 col-12">
                                    <input id="Foto" type="file" name="customFile" class="custom-file-input" v-on:change="onFileSelected" accept="image/*">
                                    <label class="custom-file-label text-left" for="customFile">Subir una foto</label>
                                    <!-- <span class="text-danger col-12" v-for="error in errors"></span> -->

                                    <!-- <span>Fotografía</span>
                                    <input type="file" id="Foto" class="custom-file-imput" name="image" v-on:change="onFileSelected" accept="image/*">
                                    <button @click="updatePhoto" class="btn btn-sm btn-light mt-3">Subir Imagen</button> -->
                                    <!-- <button class="btn btn-large btn-secondary" type="file" name="button">Subir foto</button> -->
                                  </div>
                                  <div v-if="!editMode && !createMode">
                                    <span>Empresa</span><br/>
                                    <img v-bind:src="'images/hr/'+employee.company"
                                    class="m-auto col-8 d-none d-lg-block"
                                    width="50%" alt="companylogo"/>
                                    {{employee.company}}
                                  </div>
                                  <div v-else class="col-12 mb-2">
                                    <div class="row">
                                      <label for="company">Empresa</label>
                                      <select name="company" v-model="employee.company_id" class="form-control"
                                         v-bind:class="{ 'is-invalid': errors['company_id'] }">
                                        <option v-for="cia in companies" v-bind:value="cia.id">
                                          {{cia.company}}
                                        </option>
                                      </select>
                                      <span v-if="errors['company_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['company_id'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-12 mb-2">
                                    <div class="row">
                                      <label for="stemployee">Status</label>
                                      <select name="stemployee" disabled v-model="employee.stemployee_id" class="form-control">
                                        <option v-for="st in stemployees" v-bind:value="st.id">
                                          {{st.status}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="row mb-2">
                                      <label for="fechaingreso">Ingreso</label>
                                      <input id="fechaingreso" type="date" class="form-control" disabled name="fechaingreso" v-model="employee.hired">
                                    </div>
                                    <div class="row mb-3">
                                      <label for="fechabaja">Baja</label>
                                      <input id="fechabaja" type="date" class="form-control" name="fechabaja" disabled v-model="employee.fired">
                                    </div>
                                  </div>
                                <!-- <div class="row col-12">
                                  <span class="font-italic text-muted" v-if="editMode || createMode">
                                      Los campos no habilitados se actualizan desde su respectivo apartado, y/o se generan de manera automática.
                                  </span>
                                </div> -->
                              </div>
                                <!-- Employee's information -->
                                <div class="col-12 col-lg-8">
                                  <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="departamento">Departamento</label>
                                      <select name="departamento" :disabled="!editMode && !createMode" v-model="employee.department_id" class="form-control"
                                         v-bind:class="{ 'is-invalid': errors['department_id'] }">>
                                        <option v-for="dep in departments" v-bind:value="dep.id">
                                          {{dep.department}}
                                        </option>
                                      </select>
                                      <span v-if="errors['department_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['department_id'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="role">Rol</label>
                                      <select name="role" v-model="employee.role_id" class="form-control" :disabled="!editMode && !createMode"
                                        v-bind:class="{ 'is-invalid': errors['role_id'] }">
                                        <option v-for="rol in roles" v-bind:value="rol.id">
                                          {{rol.role}}
                                        </option>
                                      </select>
                                      <span v-if="errors['role_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['role_id'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="numempleado">Núm.</label>
                                      <input id="numempleado" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.employeenumber"
                                      v-bind:class="{ 'is-invalid': errors['employeenumber'] }">
                                      <span v-if="errors['employeenumber']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['employeenumber'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="checador">Checador</label>
                                      <input id="checador" type="text" class="form-control" :disabled="!editMode && !createMode" name="checador" v-model="employee.timeclocknumber"
                                        v-bind:class="{ 'is-invalid': errors['timeclocknumber'] }">
                                        <span v-if="errors['timeclocknumber']" class="invalid-feedback" role="alert">
                                          <strong>{{ errors['timeclocknumber'] }}</strong>
                                        </span>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="fechanacimiento">Nacimiento</label>
                                      <input id="fechanacimiento" type="date" timezone="mx-MX" class="form-control" :disabled="!editMode && !createMode" name="fechanacimiento" v-model="employee.birth"
                                      v-bind:class="{ 'is-invalid': errors['birth'] }">
                                      <span v-if="errors['birth']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['birth'] }}</strong>
                                      </span>
                                    </div>

                                    <div class="col-12 col-lg-6 mb-3 d-md-none">
                                      <label for="genero">Genero</label>
                                      <select name="genero" v-model="employee.gender_id" class="form-control" :disabled="!editMode && !createMode"
                                        v-bind:class="{ 'is-invalid': errors['gender_id'] }">
                                        <option v-for="gender in genders" v-bind:value="gender.id">
                                          {{gender.gender}}
                                        </option>
                                      </select>
                                      <span v-if="errors['gender_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['gender_id'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 mb-3 d-none d-md-block">
                                      <label for="genero">Genero</label>
                                      <select name="genero" v-model="employee.gender_id" class="form-control" :disabled="!editMode && !createMode"
                                        v-bind:class="{ 'is-invalid': errors['gender_id'] }">
                                        <option v-for="gender in genders" v-bind:value="gender.id">
                                          {{gender.gender}}
                                        </option>
                                      </select>
                                      <span v-if="errors['gender_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['gender_id'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                      <label for="nss">NSS</label>
                                      <input id="nss" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.nss"
                                      v-bind:class="{ 'is-invalid': errors['nss'] }">
                                      <span v-if="errors['nss']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['nss'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row justify-content-around">
                                    <div class="col-12 col-md-5 mb-3">
                                      <label for="rfc">RFC</label>
                                      <input id="rfc" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.rfc"
                                      v-bind:class="{ 'is-invalid': errors['rfc'] }" @input="employee.rfc=$event.target.value.toUpperCase()">
                                      <span v-if="errors['rfc']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['rfc'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-md-7 mb-3">
                                      <label for="curp">CURP</label>
                                      <input id="curp" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.curp"
                                      v-bind:class="{ 'is-invalid': errors['curp'] }" @input="employee.curp=$event.target.value.toUpperCase()">
                                      <span v-if="errors['curp']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['curp'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 mb-3">
                                      <label for="apellidos">Apellido(s)</label>
                                      <input id="apellidos" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.lastname"
                                      v-bind:class="{ 'is-invalid': errors['lastname'] }" @input="employee.lastname=$event.target.value.toUpperCase()">
                                      <span v-if="errors['lastname']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['lastname'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 mb-3">
                                      <label for="nombres">Nombre(s)</label>
                                      <input id="nombres" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.name"
                                      v-bind:class="{ 'is-invalid': errors['name'] }" @input="employee.name=$event.target.value.toUpperCase()">
                                      <span v-if="errors['name']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['name'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Employee contact -->
                          <div role="tabpanel" class="tab-pane" id="Contacto">
                            <div class="row col-12">
                              <span class="font-italic text-muted text-center" v-if="editMode || createMode">
                                  Los campos no habilitados se actualizan desde su respectivo apartado, y/o se generan de manera automática.
                              </span>
                            </div>
                            <div class="container justify-content-center text-left font-weight-bold">
                              <div class="row justify-content-center">
                                <div class="text-muted my-2">
                                  <span>INFORMACIÓN DE CONTACTO</span>
                                </div>
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-12 col-lg-8 mb-3">
                                      <label for="calle">Calle</label>
                                      <input id="calle" name="calle" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.street"
                                        v-bind:class="{ 'is-invalid': errors['street'] }" @input="employee.street=$event.target.value.toUpperCase()">
                                        <span v-if="errors['street']" class="invalid-feedback" role="alert">
                                          <strong>{{ errors['street'] }}</strong>
                                        </span>
                                    </div>
                                    <div class="col-6 col-lg-2 mb-3">
                                      <label for="exterior">Núm.</label>
                                      <input id="exterior" name="exterior" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.stnumber">
                                    </div>
                                    <div class="col-6 col-lg-2 mb-3">
                                      <label for="interior">Int.</label>
                                      <input id="interior" name="interior" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.stnumberint">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8 col-lg-4 mb-3">
                                      <label for="estado">Estado</label>
                                      <select name="estado" :disabled="!editMode && !createMode" v-model="employee.state_id" @change="getMunicipalities()" class="form-control"
                                        v-bind:class="{ 'is-invalid': errors['state_id'] }">
                                        <option v-for="state in states" v-bind:value="state.id">
                                          {{state.state}}
                                        </option>
                                      </select>
                                      <span v-if="errors['state_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['state_id'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-4 col-lg-3 mb-3">
                                      <label for="cp">C.P.</label>
                                      <input id="cp" type="text" class="form-control" :disabled="!editMode && !createMode" name="cp" v-model="employee.zipcode" @change="getColonies()"
                                      v-bind:class="{ 'is-invalid': errors['zipcode'] }">
                                      <span v-if="errors['zipcode']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['zipcode'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-3">
                                      <label for="municipio">Municipio</label>
                                      <select name="municipio" :disabled="!editMode && !createMode" v-model="employee.municipality_id" @change="getColonies()" class="form-control"
                                        v-bind:class="{ 'is-invalid': errors['municipality_id'] }">
                                        <option v-for="mun in municipalities" v-bind:value="mun.id">
                                          {{mun.municipality}}
                                        </option>
                                      </select>
                                      <span v-if="errors['municipality_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['municipality_id'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="colonia">Colonia</label>
                                      <select name="colonia" :disabled="!editMode && !createMode" v-model="employee.colony_id" class="form-control"
                                        v-bind:class="{ 'is-invalid': errors['colony_id'] }">
                                        <option v-for="col in colonies" v-bind:value="col.id">
                                          {{col.colony}}
                                        </option>
                                      </select>
                                      <span v-if="errors['colony_id']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['colony_id'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="referencias">Referencias</label>
                                      <input id="referencias" name="referencias" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.particularRef"
                                        @input="employee.particularRef=$event.target.value.toUpperCase()">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="telefono">Telefono</label>
                                      <input id="telefono" type="telefono" name="telefono" class="form-control" :disabled="!editMode && !createMode" v-model="employee.phone"
                                      v-bind:class="{ 'is-invalid': errors['phone'] }">
                                      <span v-if="errors['phone']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['phone'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="celular">Celular</label>
                                      <input id="celular" type="text" name="celular" class="form-control" :disabled="!editMode && !createMode" v-model="employee.mobile"
                                      v-bind:class="{ 'is-invalid': errors['mobile'] }">
                                      <span v-if="errors['mobile']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['mobile'] }}</strong>
                                      </span>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="correo">Correo electrónico</label>
                                      <input id="correo" type="text" name="correo" class="form-control" :disabled="!editMode && !createMode" v-model="employee.email"
                                      v-bind:class="{ 'is-invalid': errors['email'] }" @input="employee.email=$event.target.value.toLowerCase()">
                                      <span v-if="errors['email']" class="invalid-feedback" role="alert">
                                        <strong>{{ errors['email'] }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="user">Usuario portal</label>
                                      <input id="user" type="text" name="user" class="form-control" disabled v-model="employee.username">
                                    </div>
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="secretkey">Secretkey</label>
                                      <input id="secretkey" type="text" name="secretkey" class="form-control" disabled v-model="employee.secretkey">
                                    </div>
                                    <div v-if="editMode" class="col-6 col-lg-3 mb-3">
                                      <div class="mt-3">
                                        <button type="button" class="col-12 btn btn-lg btn-outline-danger" name="resetSecretkey" v-on:click.submit="resetSecretkey()">ResetSC</button>
                                      </div>
                                    </div>
                                    <div v-if="editMode" class="col-6 col-lg-3 mb-3">
                                      <div class="mt-3">
                                        <button type="button" class="col-12 btn btn-lg btn-outline-danger" name="resetPassword" v-on:click.submit="resetPassword()">ResetPW</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Beneficiary -->
                          <div role="tabpanel" class="tab-pane" id="Beneficiario">
                            <div class="container justify-content-center text-left font-weight-bold">
                              <div class="row justify-content-center">
                                <div class="text-muted my-2">
                                  <span>BENEFICIARIO DEL EMPLEADO</span>
                                </div>
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-6 col-lg-8 mb-3">
                                      <label for="parentesco">Parentesco</label>
                                      <select name="parentesco" :disabled="!editMode && !createMode" v-model="employee.kidship_id" class="form-control">
                                        <option v-for="p in kinships" v-bind:value="p.id">
                                          {{p.kinship}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="nacimientob">Nacimiento</label>
                                      <input id="nacimientob" name="nacimientob" type="date" class="form-control" v-model="employee.bbirth" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="nombreb">Nombre</label>
                                      <input id="nombreb" name="nombreb" type="text" class="form-control" v-model="employee.bname" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="apellidob">Apellido</label>
                                      <input id="apellidob" name="apellidob" type="text" class="form-control" v-model="employee.blastname" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-8 mb-3">
                                      <label for="calleb">Calle</label>
                                      <input id="calleb" name="calleb" type="text" class="form-control" v-model="employee.bstreet" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-6 col-lg-2 mb-3">
                                      <label for="exteriorb">Núm.</label>
                                      <input id="exteriorb" name="exteriorb" type="text" class="form-control" v-model="employee.stnumber" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-6 col-lg-2 mb-3">
                                      <label for="interiorb">Int.</label>
                                      <input id="interiorb" name="interiorb" type="text" class="form-control" v-model="employee.stnumberint" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-8 col-lg-4 mb-3">
                                      <label for="estadob">Estado</label>
                                      <select name="estadob" :disabled="!editMode && !createMode" @change="getMunicipalitiesB()" v-model="employee.bstate_id" class="form-control">
                                        <option v-for="e in states" v-bind:value="e.id">
                                          {{e.state}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-4 col-lg-3 mb-3">
                                      <label for="cpb">C.P.</label>
                                      <input id="cpb" type="text" class="form-control" disabled name="cpb" :disabled="!editMode && !createMode" v-model="employee.bzipcode" @change="getColoniesB()">
                                    </div>
                                    <div class="col-12 col-lg-5 mb-3">
                                      <label for="municipiob">Municipio</label>
                                      <select name="municipiob" :disabled="!editMode && !createMode" @change="getColoniesB()" v-model="employee.bmunicipality_id" class="form-control">
                                        <option v-for="m in municipalities" v-bind:value="m.id">
                                          {{m.municipality}}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="coloniab">Colonia</label>
                                      <select name="coloniab" :disabled="!editMode && !createMode" v-model="employee.bcolony_id" class="form-control">
                                        <option v-for="c in colonies" v-bind:value="c.id">
                                          {{c.colony}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="referenciasb">Referencias</label>
                                      <input id="referenciasb" name="localidadb" type="text" class="form-control" v-model="employee.bparticularref" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="telefonob">Telefono</label>
                                      <input id="telefonob" type="text" name="telefonob" class="form-control" v-model="employee.bphone" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-6 col-lg-3 mb-3">
                                      <label for="celularb">Celular</label>
                                      <input id="celularb" type="text" name="celularb" class="form-control" v-model="employee.bmobile" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="correob">Correo electrónico</label>
                                      <input id="correob" type="text" name="correob" class="form-control" v-model="employee.bemail" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Payroll -->
                          <div role="tabpanel" class="tab-pane" id="Nomina">
                            <div class="row col-12">
                              <span class="font-italic text-muted text-center" v-if="editMode || createMode">
                                  Los campos no habilitados se actualizan desde su respectivo apartado, y/o se generan de manera automática.
                              </span>
                            </div>
                            <div class="container justify-content-center text-left font-weight-bold">
                              <div class="row justify-content-center">
                                <div class="text-muted my-2">
                                  <span>CONTRATO</span>
                                </div>
                                <div class="col-12">

                                  <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                      <label for="contrato">Tipo</label>
                                      <input id="contrato" name="contrato" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="inicontrato">Inicia</label>
                                      <input id="inicontrato" name="inicontrato" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="fincontrato">Termina</label>
                                      <input id="fincontrato" name="fincontrato" type="text" class="form-control" disabled>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                      <label for="puesto">Puesto</label>
                                      <input id="puesto" name="puesto" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="fechapuesto">Fecha</label>
                                      <input id="fechapuesto" name="fechapuesto" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="sueldo">Sueldo</label>
                                      <input id="sueldo" name="sueldo" type="text" class="form-control" disabled>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="periodo">Período de pago</label>
                                      <input id="periodo" name="periodo" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="fpago">Forma de pago</label>
                                      <input id="fpago" name="fpago" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-12 col-lg-4 mb-3">
                                      <label for="banco">Banco</label>
                                      <select name="banco" :disabled="!editMode && !createMode" v-model="employee.bank_id" class="form-control">
                                        <option v-for="b in banks" v-bind:value="b.id">
                                          {{b.bankname}}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-5 mb-3">
                                      <label for="cuenta">No. cuenta</label>
                                      <input id="cuenta" name="cuenta" type="text" class="form-control" :disabled="!editMode && !createMode"  v-model="employee.bankAccountNumber">
                                    </div>
                                    <div class="col-12 col-lg-7 mb-3">
                                      <label for="clabe">CLABE</label>
                                      <input id="clabe" name="clabe" type="text" class="form-control" :disabled="!editMode && !createMode"  v-model="employee.bankCLABENumber">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-6 mb-3">
                                      <label for="tarjeta">No. Tarjeta</label>
                                      <input id="tarjeta" name="tarjeta" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.cardNumber">
                                    </div>
                                    <div class="col-7 col-lg-4 mb-3">
                                      <label for="tipotarjeta">Tipo tarjeta</label>
                                      <input id="tipotarjeta" name="tipotarjeta" type="text" class="form-control" :disabled="!editMode && !createMode"  v-model="employee.cardType">
                                    </div>
                                    <div class="col-5 col-lg-2 mb-3">
                                      <label for="vencimiento">Vencimiento</label>
                                      <input id="vencimiento" name="vencimiento" type="text" class="form-control" :disabled="!editMode && !createMode" v-model="employee.cardExpirationDate" v-on:keyup="formatoExpiration">
                                    </div>
                                  </div>
                                  <div class="text-muted text-center mt-2 mb-4">
                                    <span>CRÉDITOS Y PRÉSTAMOS</span>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                      <label for="infonavit">Crédito infonavit</label>
                                      <input id="infonavit" name="infonavit" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="fechacredito">Fecha crédito</label>
                                      <input id="fechacredito" name="fechacredito" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="factordescuento">Descuento </label>
                                      <input id="factordescuento" name="factordescuento" type="text" class="form-control" disabled>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-lg-4 mb-3">
                                      <label for="prestamo">Préstamo empresa</label>
                                      <input id="prestamo" name="prestamo" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="fechaprestamo">Fecha préstamo</label>
                                      <input id="fechaprestamo" name="fechaprestamo" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="col-6 col-lg-4 mb-3">
                                      <label for="descuento">Descuento </label>
                                      <input id="descuento" name="descuento" type="text" class="form-control" disabled>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Other information -->
                          <div role="tabpanel" class="tab-pane" id="Expediente">
                            <div class="container justify-content-center text-left font-weight-bold">
                              <div class="row justify-content-center">
                                <div class="text-muted mt-2 mb-4">
                                  <span>EXPEDIENTE DEL EMPLEADO</span>
                                </div>
                                <div class="col-12">
                                  <div class="row">
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="solicitud" :checked="employee.solicitud" required>
                                      <label class="custom-control-label" for="solicitud">Solicitud elaborada</label>
                                    </div>
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="certificado" :checked="employee.solicitud" required>
                                      <label class="custom-control-label" for="certificado">Certificado médico</label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="fotografias" :checked="employee.solicitud"  required>
                                      <label class="custom-control-label" for="fotografias">Fotografías</label>
                                    </div>
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="cartapolicia" :checked="employee.solicitud" required>
                                      <label class="custom-control-label" for="cartapolicia">Carta policía</label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="recomendaciones" :checked="employee.solicitud"  required>
                                      <label class="custom-control-label" for="recomendaciones">Recomendaciones</label>
                                    </div>
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="comprobantedomicilio" :checked="employee.solicitud" required>
                                      <label class="custom-control-label" for="comprobantedomicilio">Comprobante domicilio</label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="datosbeneficiario" :checked="employee.solicitud"  required>
                                      <label class="custom-control-label" for="datosbeneficiario">Datos beneficiario</label>
                                    </div>
                                    <div class="was-validated col-6 custom-control custom-checkbox mb-3">
                                      <input type="checkbox" class="custom-control-input" id="credencial" :checked="employee.solicitud"  required>
                                      <label class="custom-control-label" for="credencial">Credencial entregada</label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 mb-3">
                                      <label for="tallauniforme">Talla uniforme</label>
                                      <input id="tallauniforme" name="tallauniforme" type="text" class="form-control" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-6 mb-3">
                                      <label for="tallacalzado">Talla calzado</label>
                                      <input id="tallacalzado" name="tallacalzado" type="text" class="form-control" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6 mb-3">
                                      <label for="sangre">Tipo de sangre</label>
                                      <select name="sangre" :disabled="!editMode && !createMode" v-model="employee.bloodtype_id" class="form-control">
                                        <option v-for="s in bloodtypes" v-bind:value="s.id">
                                          {{s.type}}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-6 mb-3">
                                      <label for="donante">Dona organos</label>
                                      <input id="donante" type="text" name="donante" class="form-control" :disabled="!editMode && !createMode">
                                    </div>
                                    <div class="col-12 mb-3">
                                      <label for="alergias">Alergias</label>
                                      <input id="alergias" type="text" name="alergias" class="form-control" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 mb-3">
                                      <label for="anotaciones">Anotaciones marginales</label>
                                      <input id="anotaciones" name="anotaciones" type="text" class="form-control mb-1" :disabled="!editMode && !createMode">
                                      <input id="anotaciones2" name="anotaciones2" type="text" class="form-control mb-1" :disabled="!editMode && !createMode">
                                      <input id="anotaciones3" name="anotaciones3" type="text" class="form-control mb-1" :disabled="!editMode && !createMode">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div><!-- div tabbable fin -->

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <div class="col-10 col-lg-3 ">
                <!-- <button type="button" data-dismiss="modal" v-on:click.prevent="" class="btn-lg btn-danger btn-block">Cancelar</button> -->
                <button type="button" data-dismiss="modal" class="btn-lg btn-danger btn-block">Cancelar</button>
              </div>
              <div class="col-10 col-lg-3">
                <button type="button" v-bind:class="{'d-none' : (!editMode && !createMode) || tabActual==='#Personal'}" v-on:click.prevent="prevTab()" class="btn-lg btn-block btn-info">Anterior</button>
              </div>
              <div class="col-10 col-lg-3">
                <button type="button" v-if="!editMode && !createMode" v-on:click.prevent="habilitaEdicion()" class="btn-lg btn-primary btn-block">Actualizar</button>
                <button type="button" v-else-if="tabActual!='#Expediente'" v-on:click.prevent="nextTab()" class="btn-lg btn-info btn-block">Siguiente</button>
                <button type="button" v-else v-on:click.prevent="saveEmployee()" class="btn-lg btn-success btn-block">Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FIN DEL MODAL -->
  </div>
</template>
<script>
  export default {
    data() {
      return {
        banks: [],
        bloodtypes: [],
        colonies: [],
        companies: [],
        createMode: false,
        departments: [],
        editMode: false,
        employee: {
          //DATOS GENERALES
          id: "",
          photo: "no data",
          company_id: "",
          company: "no data",
          stemployee_id: "",
          hired: "",
          fired: "",
          department_id: "",
          department: "",
          role_id: "4",
          role:"operario",
          employeenumber: "",
          timeclocknumber: "",
          birth: "",
          gender: "",
          nss: "",
          rfc: "",
          curp: "",
          lastname: "",
          name: "",

          //DATOS DE CONTACTO
          street: "",
          stnumber: "",
          stnumberint: "",
          state_id: "",
          zipcode: "",
          municipality_id: "",
          colony_id:"",
          particularRef: "",
          phone: "",
          mobile: "",
          email: "",
          username: "",
          secretkey: "",
          password: "",

          //DATOS DE BENEFICIARIO
          kinship_id: "",
          bbirth: "",
          bname: "",
          blastname: "",
          bstreet: "",
          bstnumber: "",
          bstnumberint: "",
          bstate_id: "",
          bzipcode: "",
          bmunicipality_id: "",
          bcolony_id:"",
          bparticularref: "",
          bphone: "",
          bmobile: "",
          bemail: "",

          //DATOS BANCARIOS Y NOMINA
          contracttype_id: "",
          contract_start: "",
          contract_end: "",
          job:"",
          jobissue:"",
          jobwage: "",
          payrollperiod_id: "",
          payrollform: "",
          bank_id: "",
          bankAccountNumber: "",
          bankCLABENumber: "",
          cardNumber: "",
          cardType: "",
          cardExpirationDate: "",
          infonavitCredit: "",
          infonavitStart: "",
          infonavitDiscount: "",
          infonavitDiscountValue: "",


          //OTROS DATOS DE EXPEDIENTE
          jobapplication: "",
          photographs: "",
          recommendations: "",
          beneficiaryData: "",
          medicalcertificate: "",
          criminalRecords: "",
          proofAddress: "",
          badgeDelivered: "",
        },
        employees: [],
        errors: {
          company_id: null,
        },
        genders: [],
        kinships: [],
        municipalities: [],
        needUpdate: true,
        pageact: 0,
        roles: [],
        states: [],
        stemployees: [],
        tabindex: 0,
        tabActual: "#Personal",
        tabAnterior: "#Personal",
        numerrs: 0,
        needValidate: true,
      }
    },

    mounted() {
      this.getEmployees();
      $(this.$refs.showemployee_modal).on("hidden.bs.modal", this.closeModal);
      $('.tabbable .nav-tabs a').on("click", this.tabChanges);
    },

    updated() {
      if (this.needUpdate) {
        // alert('Pagina actualizandose...')
        $('#table').DataTable().destroy();
        $('#table').DataTable({
          "language": {
            "sProcessing":     "Procesando...",
            "sLengthMenu":     "Mostrar _MENU_ registros",
            "sZeroRecords":    "No se encontraron resultados",
            "sEmptyTable":     "Ningún dato disponible en esta tabla =(",
            "sInfo":           "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "sInfoEmpty":      "Mostrando registros del 0 al 0 de un total de 0 registros",
            "sInfoFiltered":   "(filtrado de un total de _MAX_ registros)",
            "sInfoPostFix":    "",
            "sSearch":         "Buscar:",
            "sUrl":            "",
            "sInfoThousands":  ",",
            "sLoadingRecords": "Cargando...",
            "oPaginate": {
              "sFirst":    "Primero",
              "sLast":     "Último",
              "sNext":     "Siguiente",
              "sPrevious": "Anterior"
            },
            "oAria": {
              "sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
              "sSortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            "buttons": {
              "copy": "Copiar",
              "colvis": "Visibilidad"
            }
          },
          "order": [[ 0, 'asc' ]]
        }).page(this.pageact).draw('page');
        // alert('Modificar entro')
        this.needUpdate = false;
      }
    },

    methods: {
      addEmployee(){
        const Toast = Swal.mixin({
          toast: false,
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'success',
          title: 'Correcto',
          text: 'Empleado creado exitosamente'
        })
        this.createMode = false;
        $('#show_employee').modal('hide');
        this.needUpdate = true;
      },
      closeModal(){
        $('.tabbable .nav-tabs a[href="#Personal"]').tab('show');
        this.tabActual=this.tabAnterior="#Personal";
        this.errors={
          company_id: null,
        };
        this.numerrs=0;
        // this.employee.photo = "no data";
        let preview = document.getElementById('preview');
        preview.innerHTML = '';
        $('#Foto').siblings(".custom-file-label").removeClass("selected").html("Subir una foto");
      },
      createEmployee(){
        this.editMode = true;
        this.needUpdate = false;
        this.fillForm(null);
        $('#show_employee').modal('show');
      },
      fillForm(employee){
        this.editMode = false;
        if(employee){
          this.createMode = false;
          this.employee.id= employee.id;
          if(employee.photo){
            this.employee.photo = employee.photo;
          }
          else{
            this.employee.photo = "no data";
          }
          if(employee.company){
            this.employee.company = employee.company;
          }
          else{
              this.employee.company = "No asignado";
          }
          this.employee.company_id= employee.company_id;
          this.employee.stemployee_id= employee.stemployee_id;
          this.employee.hired= employee.hired;
          this.employee.fired= employee.fired;
          this.employee.department_id= employee.department_id;
          this.employee.role_id= employee.role_id;
          this.employee.employeenumber= employee.employeenumber;
          this.employee.timeclocknumber= employee.timeclocknumber;
          this.employee.birth= employee.birth;
          this.employee.gender_id= employee.gender_id;
          this.employee.nss= employee.nss;
          this.employee.rfc= employee.rfc;
          this.employee.curp= employee.curp;
          this.employee.lastname= employee.lastname;
          this.employee.name= employee.name;
          this.employee.street= employee.street;
          this.employee.stnumber= employee.stnumber;
          this.employee.stnumberint= employee.stnumberint;
          this.employee.state_id= employee.state_id;
          this.employee.zipcode= employee.zipcode;
          this.employee.municipality_id= employee.municipality_id;
          this.employee.colony_id= employee.colony_id;
          this.employee.particularRef= employee.particularRef;
          this.employee.phone= employee.phone;
          this.employee.mobile= employee.mobile;
          this.employee.email= employee.email;
          this.employee.username= employee.username;
          this.employee.secretkey= employee.secretkey;

          this.employee.kinship_id = employee.kinship_id;
          this.employee.bname= employee.bname;
          this.employee.blastname = employee.blastname;
          this.employee.bstreet= employee.bstreet;
          this.employee.bstnumber= employee.bstnumber;
          this.employee.bstnumberint= employee.bstnumberint;
          this.employee.bstate_id= employee.bstate_id;
          this.employee.bzipcode= employee.bzipcode;
          this.employee.bmunicipality_id= employee.bmunicipality_id;
          this.employee.bcolony_id= employee.bcolony_id;
          this.employee.bparticularref= employee.bparticularref;
          this.employee.bphone= employee.bphone;
          this.employee.bmobile= employee.bmobile;
          this.employee.bemail= employee.bemail;

          this.employee.bank_id= employee.bank_id;
          this.employee.bankAccountNumber= employee.bankAccountNumber;
          this.employee.cardType= employee.cardType;
          this.employee.cardNumber= employee.cardNumber;
          this.employee.cardExpirationDate= employee.cardExpirationDate;
        }
        else{
          this.createMode = true;
          this.employee.id= "";
          this.employee.photo = "no data";
          this.employee.company = "No asignado";
          this.employee.company_id= "";
          this.employee.stemployee_id= "";
          this.employee.hired= "";
          this.employee.fired= "";
          this.employee.department_id= "";
          this.employee.role_id= "";
          this.employee.employeenumber= "";
          this.employee.timeclocknumber= "";
          this.employee.birth= "";
          this.employee.gender_id= "";
          this.employee.nss= "";
          this.employee.rfc= "";
          this.employee.curp= "";
          this.employee.lastname= "";
          this.employee.name= "";
          this.employee.street= "";
          this.employee.stnumber= "";
          this.employee.stnumberint= "";
          this.employee.state_id= "";
          this.employee.zipcode= "";
          this.employee.municipality_id= "";
          this.employee.colony_id= "";
          this.employee.particularRef= "";
          this.employee.phone= "";
          this.employee.mobile= "";
          this.employee.email= "";
          this.employee.username= "";
          this.employee.secretkey= "";

          this.employee.kidship_id = "";
          this.employee.bbirth = "";
          this.employee.bname= "";
          this.employee.blastname= "";
          this.employee.bstreet= "";
          this.employee.bstnumber= "";
          this.employee.bstnumberint= "";
          this.employee.bstate_id= "";
          this.employee.bzipcode= "";
          this.employee.bmunicipality_id= "";
          this.employee.bcolony_id= "";
          this.employee.bparticularref= "";
          this.employee.bphone= "";
          this.employee.bmobile= "";
          this.employee.bemail= "";

          this.employee.bank_id= "";
          this.employee.bankAccountNumber= "";
          this.employee.cardType= "";
          this.employee.cardNumber= "";
          this.employee.cardExpirationDate= "";
        }
      },
      formatoCard(){
        let campo = this.employee.cardNumber;
        if(campo.length == 4 || campo.length == 9 || campo.length == 14) {
          this.employee.cardNumber = campo + "-";
        }
      },
      formatoClabe(){
        let campo = this.employee.bankCLABENumber;
        if(campo.length == 3 || campo.length == 7 || campo.length == 19) {
          this.employee.bankCLABENumber = campo + "-";
        }
      },
      formatoExpiration(){
        let campo = this.employee.cardExpirationDate;
        if(campo.length == 2) {
          this.employee.cardExpirationDate = campo + "/";
        }
      },
      getImage(){
        this.employee.photo = event.tarjet.files[0];
      },
      habilitaEdicion(){
        this.editMode = true;
        var $active = $('.nav-tabs li>.active');
        $active.parent().find('a[data-toggle="tab"]').click();
      },
      getColonies(){
        var urlColonies;
        if(this.employee.zipcode!=""){
          urlColonies = '/colonias?zipcode='+this.employee.zipcode;
        }
        else if(this.employee.municipality_id!=""){
          urlColonies = '/colonias?municipality='+this.employee.municipality_id;
        }
        else{
        }
        axios.get(urlColonies).then(response =>{
          this.colonies = response.data;
          if(this.colonies[0].municipality_id){
            this.employee.municipality_id = this.colonies[0].municipality_id;
          }
        });
      },
      getColoniesB(){
        var urlColonies;
        if(this.employee.bzipcode!=""){
          urlColonies = '/colonias?zipcode='+this.employee.bzipcode;
        }
        else if(this.employee.bmunicipality_id!=""){
          urlColonies = '/colonias?municipality='+this.employee.bmunicipality_id;
        }
        else{
        }
        axios.get(urlColonies).then(response =>{
          this.colonies = response.data;
          if(this.colonies[0].bmunicipality_id){
            this.employee.bmunicipality_id = this.colonies[0].bmunicipality_id;
          }
        });
      },
      getEmployees(){
        var urlEmployees = '/employees';
        axios.get(urlEmployees).then(response =>{
          this.employees = response.data.employees;
          this.roles = response.data.roles;
          this.stemployees = response.data.stemployees;
          this.departments = response.data.departments;
          this.companies = response.data.companies;
          this.states = response.data.states;
          this.kinships = response.data.kinships;
          this.banks = response.data.banks;
          this.bloodtypes = response.data.bloodtypes;
          this.genders = response.data.genders;
        });
      },
      getMunicipalities(){
        this.colonies = [];
        var urlMunicipalities = '/municipios?state='+this.employee.state_id;
        axios.get(urlMunicipalities).then(response =>{
          this.municipalities = response.data;
        });
      },
      getMunicipalitiesB(){
        this.colonies = [];
        var urlMunicipalities = '/municipios?state='+this.employee.bstate_id;
        axios.get(urlMunicipalities).then(response =>{
          this.municipalities = response.data;
        });
      },
      nextTab(){
        var $active = $('.nav-tabs li>.active');
        // $active.parent().next().find('.nav-link').removeClass('disabled');
        $active.parent().next().find('a[data-toggle="tab"]').click();
        $(this.$refs.showemployee_modal).animate({scrollTop: '0px'}, 0);
      },
      prevTab(){
        var $active = $('.nav-tabs li>.active');
        $active.parent().prev().find('a[data-toggle="tab"]').click();
        $(this.$refs.showemployee_modal).animate({scrollTop: '0px'}, 0);
      },
      resetPassword(){

      },
      resetSecretkey(){

      },
      onFileSelected(){
        let carga = event.target;
        if(carga.files && carga.files[0]){
          this.employee.photo = carga.files[0];
          let fileName = $('#Foto').val().split("\\").pop();
          let extension = $('#Foto').val().split("\.").pop();
          let types = ['jpg','png','jpeg'];
          if($.inArray(extension, types)>=0){
            $('#Foto').siblings(".custom-file-label").addClass("selected").html("Foto adjuntada");
            let reader = new FileReader();
            reader.onload = function(){
              let preview = document.getElementById('preview'),
                  image = document.createElement('img');

              image.src = reader.result;

              preview.innerHTML = '';
              preview.append(image);
            };
            reader.readAsDataURL(carga.files[0]);
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'No compatible',
              text: 'Solo puedes subir imagenes jpg, png o jpeg',
            })
            $('#Foto').siblings(".custom-file-label").removeClass("selected").html("Subir una foto");
            this.employee.photo = "";
          }
        }
      },
      saveEmployee(){
        $('btn-lg').blur();
        if(this.validateEmployee()){
          if(this.editMode){
            this.updateEmployee();
          }
          else{
            this.addEmployee();
          }
        }
        else{
          Swal.fire({
            icon: 'warning',
            title: '<span style="color: orange"> Verifique <span>',
            html: 'Algunos campos necesitan su atención',
          })
          $(this).scrollTo(0, 0);
        }
      },
      showEmployee(employee){
        this.editMode = false;
        this.needUpdate = false;
        this.fillForm(employee);
        this.getMunicipalities();
        this.getColonies();
        $('#show_employee').modal('show');
      },
      tabChanges(e){
        if(this.editMode || this.createMode){
          let sigue = false;
          this.errors = [];
          this.tabAnterior = this.tabActual;
          this.tabActual = $(e.target).attr('href');
          switch (this.tabAnterior) {
            case "#Personal":
              sigue = this.validatePersonal();
              break;
            case "#Contacto":
              sigue = this.validateContacto();
              break;
            case "#Beneficiario":
              sigue = this.validateBeneficiario();
              break;
            case "#Nomina":
              sigue = this.validateNomina();
              break;
            case "#Expediente":
              sigue = this.validateExpediente();
              break;
            default:
          }
          if(!sigue){
            e.stopImmediatePropagation();
            this.tabActual=this.tabAnterior;
            this.numerrs = 0;
            Swal.fire({
              icon: 'warning',
              title: '<span style="color: orange"> Verifique <span>',
              html: 'Algunos campos necesitan su atención',
            })
          }
        }
      },
      updateEmployee(){
        alert('Actualizando weon....')
        this.editMode = false;
        $('#show_employee').modal('hide');
        this.needUpdate = true;
      },
      updatePhoto(){
        //Creamos el formData
         var data = new  FormData();
         //Añadimos la imagen seleccionada
         data.append('photo', this.employee.photo);
         //Añadimos el método PUT dentro del formData
         // Como lo hacíamos desde un formulario simple _(no ajax)_
         data.append('_method', 'PUT');
         //Enviamos la petición
         axios.post('/dashboard/avatar',data)
         .then(response => {

         })
      },
      validateEmployee(){
        if(this.employee.company_id == ""){
          this.errors["company_id"]="Seleccione una compañía";
          this.numerrs++;
        }
          //DATOS GENERALES
          // this.employee.id: "",
          // photo: "no data",
          // company_id: "",
          // company: "no data",
          // stemployee_id: "",
          // hired: "",
          // fired: "",
          // department_id: "",
          // department: "",
          // role_id: "4",
          // role:"operario",
          // employeenumber: "",
          // timeclocknumber: "",
          // birth: "",
          // gender: "",
          // nss: "",
          // rfc: "",
          // curp: "",
          // lastname: "",
          // name: "",
          //
          // //DATOS DE CONTACTO
          // street: "",
          // stnumber: "",
          // stnumberint: "",
          // state_id: "",
          // zipcode: "",
          // municipality_id: "",
          // colony_id:"",
          // particularRef: "",
          // phone: "",
          // mobile: "",
          // email: "",
          // username: "",
          // secretkey: "",
          // password: "",
          //
          // //DATOS DE BENEFICIARIO
          // kinship_id: "",
          // bbirth: "",
          // bname: "",
          // blastname: "",
          // bstreet: "",
          // bstnumber: "",
          // bstnumberint: "",
          // bstate_id: "",
          // bzipcode: "",
          // bmunicipality_id: "",
          // bcolony_id:"",
          // bparticularref: "",
          // bphone: "",
          // bmobile: "",
          // bemail: "",
          //
          // //DATOS BANCARIOS Y NOMINA
          // contracttype_id: "",
          // contract_start: "",
          // contract_end: "",
          // job:"",
          // jobissue:"",
          // jobwage: "",
          // payrollperiod_id: "",
          // payrollform: "",
          // bank_id: "",
          // bankAccountNumber: "",
          // bankCLABENumber: "",
          // cardNumber: "",
          // cardType: "",
          // cardExpirationDate: "",
          // infonavitCredit: "",
          // infonavitStart: "",
          // infonavitDiscount: "",
          // infonavitDiscountValue: "",
          //
          //
          // //OTROS DATOS DE EXPEDIENTE
          // jobapplication: "",
          // photographs: "",
          // recommendations: "",
          // beneficiaryData: "",
          // medicalcertificate: "",
          // criminalRecords: "",
          // proofAddress: "",
          // badgeDelivered: ""
          if(this.numerrs == 0){
            // this.resetErrors();
            return true;
          }
          // alert("Errores encontrados "+nerr);
          // alert(this.errors['company_id']);
          this.tabActual=this.tabAnterior="#Personal";
          $('.tabbable .nav-tabs a[href="#Personal"]').tab('show');
          return false;
      },
      validatePersonal(){
        if(this.employee.company_id == ""){
          this.errors["company_id"]="Seleccione una compañía";
          this.numerrs++;
        }
        if(this.employee.department_id == ""){
          this.errors["department_id"]="Seleccione un departamento";
          this.numerrs++;
        }
        if(this.employee.role_id == ""){
          this.errors["role_id"]="Seleccione un rol";
          this.numerrs++;
        }
        if(this.employee.employeenumber == ""){
          this.errors["employeenumber"]="Ingrese un número de empleado";
          this.numerrs++;
        }
        if(isNaN(this.employee.employeenumber)){
          this.errors["employeenumber"]="Ingrese sólo números";
          this.numerrs++;
        }
        if(this.employee.timeclocknumber == ""){
          this.errors["timeclocknumber"]="Ingrese un número de checador";
          this.numerrs++;
        }
        if(isNaN(this.employee.timeclocknumber)){
          this.errors["timeclocknumber"]="Ingrese sólo números";
          this.numerrs++;
        }
        if(this.employee.birth==""){
          this.errors["birth"]="Ingrese fecha de nacimiento";
          this.numerrs++;
        }
        if(this.employee.gender_id==""){
          this.errors["gender_id"]="Seleccione un género";
          this.numerrs++;
        }
        if(this.employee.nss==""){
          this.errors["nss"]="Ingrese un NSS";
          this.numerrs++;
        }
        var er_nss = new RegExp("^[0-9]{11}$");
        if(!er_nss.test(this.employee.nss)){
          this.errors["nss"]="Ingrese un NSS válido";
          this.numerrs++;
        }
        if(isNaN(this.employee.timeclocknumber)){
          this.errors["timeclocknumber"]="Ingrese sólo números";
          this.numerrs++;
        }
        if(this.employee.rfc==""){
          this.errors["rfc"]="Ingrese un RFC";
          this.numerrs++;
        }
        var er_rfc = new RegExp("^([A-Z]|[a-z]){4}\\d{6}([0-9]|[A-Z]|[a-z]){2}([0-9]|[a]|[A]){1}$");
        if(!er_rfc.test(this.employee.rfc)){
          this.errors["rfc"]="Ingrese un RFC válido";
          this.numerrs++;
        }
        if(this.employee.curp==""){
          this.errors["curp"]="Ingrese un CURP";
          this.numerrs++;
        }
        var er_curp = new RegExp("^([A-Z]|[a-z]){4}\\d{6}([A-Z]|[a-z]){6}([A-Z]|[a-z]|[0-9]){1}[0-9]{1}$");
        if(!er_curp.test(this.employee.curp)){
          this.errors["curp"]="Ingrese un CURP válido";
          this.numerrs++;
        }
        if(this.employee.lastname==""){
          this.errors["lastname"]="Ingrese el o los apellidos";
          this.numerrs++;
        }
        if(this.employee.name==""){
          this.errors["name"]="Ingrese el o los nombres";
          this.numerrs++;
        }
        if(this.numerrs == 0){
          return true;
        }
        // alert('Errores encontrados: '+this.numerrs);
        return false;
      },
      validateContacto(){
        if(this.employee.street == ""){
          this.errors["street"] = "Ingrese el nombre de la calle";
          this.numerrs++;
        }
        if(this.employee.stnumber == ""){
          this.employee.stnumber = "S/N";
        }
        if(this.employee.state_id==""){
          this.errors["state_id"] = "Seleccione un estado";
          this.numerrs++;
        }
        if(this.employee.zipcode==""){
          this.errors["zipcode"] = "Ingrese un código postal";
          this.numerrs++;
        }
        var er_zipcode = new RegExp("^[0-9]{5}$");
        if(!er_zipcode.test(this.employee.zipcode)){
          this.errors["zipcode"]="Ingrese un código postal válido";
          this.numerrs++;
        }
        if(this.employee.municipality_id==""){
          this.errors["municipality_id"] = "Seleccione un municipio";
          this.numerrs++;
        }
        if(this.employee.colony_id==""){
          this.errors["colony_id"] = "Seleccione una colonia";
          this.numerrs++;
        }
        if(this.employee.phone == "" && this.employee.mobile == ""){
          this.errors["phone"] = "Ingrese al menos un número teléfonico";
          this.numerrs++;
        }
        else{
          var er_phone = new RegExp("^[0-9]{10}$");
          if(!er_phone.test(this.employee.phone) && this.employee.phone != ""){
            this.errors["phone"]="El teléfono debe ser a 10 dígitos";
            this.numerrs++;
          }
          if(!er_phone.test(this.employee.mobile) && this.employee.mobile != ""){
            this.errors["mobile"]="El celular debe ser a 10 dígitos";
            this.numerrs++;
          }
        }
        if(this.employee.email == ""){
          this.errors["email"]="Ingrese un correo electrónico";
          this.numerrs++;
        }
        var er_email = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
        if(!er_email.test(this.employee.email)){
          this.errors["email"]="Ingrese un correo electrónico válido";
          this.numerrs++;
        }
        if(this.numerrs == 0){
          return true;
        }
        return false;
      },
      validateBeneficiario(){
        if(this.employee.kidship_id == ""){
          this.errors["kidship_id"]="Seleccione el parentezco";
          this.numerrs++;
        }
        if(this.employee.bbirth == ""){
          this.errors["bbirth"]="Ingrese la fecha de nacimiento";
          this.numerrs++;
        }
        if(this.employee.bname == ""){
          this.errors["bname"]="Ingrese el o los nombres del beneficiario";
          this.numerrs++;
        }
        if(this.employee.blastname == ""){
          this.errors["blastname"]="Ingrese el o los apellidos del beneficiario";
          this.numerrs++;
        }
        if(this.employee.bstreet == ""){
          this.errors["bstreet"]="Ingrese el nombre de la calle";
          this.numerrs++;
        }
        if(this.employee.bstnumber == ""){
          this.employee.bstnumber = "S/N";
        }
        if(this.employee.bstate_id == ""){
          this.errors["bstate_id"]="Seleccione un Estado";
          this.numerrs++;
        }
        if(this.employee.bzipcode == ""){
          this.errors["bzipcode"]="Ingrese un código postal";
          this.numerrs++;
        }
        var er_zipcode = new RegExp("^[0-9]{5}$");
        if(!er_zipcode.test(this.employee.bzipcode)){
          this.errors["bzipcode"]="Ingrese un código postal válido";
          this.numerrs++;
        }
        if(this.employee.bmunicipality_id==""){
          this.errors["bmunicipality_id"] = "Seleccione un municipio";
          this.numerrs++;
        }
        if(this.employee.bcolony_id==""){
          this.errors["bcolony_id"] = "Seleccione una colonia";
          this.numerrs++;
        }
        if(this.employee.bphone == "" && this.employee.bmobile == ""){
          this.errors["bphone"] = "Ingrese al menos un número teléfonico";
          this.numerrs++;
        }
        else{
          var er_phone = new RegExp("^[0-9]{10}$");
          if(!er_phone.test(this.employee.bphone) && this.employee.bphone != ""){
            this.errors["bphone"]="El teléfono debe ser a 10 dígitos";
            this.numerrs++;
          }
          if(!er_phone.test(this.employee.bmobile) && this.employee.bmobile != ""){
            this.errors["bmobile"]="El celular debe ser a 10 dígitos";
            this.numerrs++;
          }
        }
        if(this.employee.bemail == ""){
          this.errors["bemail"]="Ingrese un correo electrónico";
          this.numerrs++;
        }
        var er_email = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
        if(!er_email.test(this.employee.bemail)){
          this.errors["bemail"]="Ingrese un correo electrónico válido";
          this.numerrs++;
        }
        if(this.numerrs == 0){
          return true;
        }
        return false;
      },
      validateNomina(){
        if(this.employee.bank_id == ""){
          this.errors["bank_id"]="Seleccione un banco";
          this.numerrs++;
        }
        if(this.employee.bankAccountNumber == ""){
          this.errors["bankAccountNumber"]="Ingrese un número de cuenta";
          this.numerrs++;
        }
        else{
          var er_ctaBanco = new RegExp("^[0-9]{11}$");
          if(!er_ctaBanco.test(this.employee.bankAccountNumber)){
            this.errors["bankAccountNumber"]="Ingrese una cuenta de 11 digitos";
            this.numerrs++;
          }
        }
        if(this.employee.bankCLABENumber == ""){
          this.errors["bankCLABENumber"]="Ingrese una CLABE interbancaria";
          this.numerrs++;
        }
        else{
          var er_ctaCLABE = new RegExp("^[0-9]{18}$");
          if(!er_ctaCLABE.test(this.employee.bankCLABENumber)){
            this.errors["bankCLABENumber"]="Ingrese una cuenta de 18 digitos";
            this.numerrs++;
          }
        }
        if(this.employee.cardNumber == ""){
          this.errors["cardNumber"]="Ingrese un número de tarjeta";
          this.numerrs++;
        }
        else{
          var er_Tarjeta = new RegExp("^[0-9]{16}$");
          if(!er_Tarjeta.test(this.employee.cardNumber)){
            this.errors["cardNumber"]="Ingrese una tarjeta de 16 digitos";
            this.numerrs++;
          }
        }
        if(this.numerrs == 0){
          return true;
        }
        return false;
      },
      validateExpediente(){
        return true;
      },
    }
  }
</script>
